<template>
  <a-modal :confirm-loading="loading" title="编辑触发规则" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="事件源" prop="source_type">
        <source-type-select v-model="form.source_type" :disabled="true"></source-type-select>
      </a-form-model-item>
      <a-form-model-item label="触发条件" prop="condition">
        <alert-strategy-condition-select v-model="form.condition"></alert-strategy-condition-select>
      </a-form-model-item>
      <a-form-model-item label="资源" prop="sources">
        <source-select v-model="form.sources" :label-in-value="true" mode="multiple" :source-type="form.source_type"></source-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getAlertStrategyTrigger,
  updateAlertStrategyTrigger
} from '@/api/alert-strategy-trigger'
import AlertStrategyConditionSelect from '@/components/select/AlertStrategyConditionSelect'
import SourceSelect from '@/components/select/SourceSelect'
import SourceTypeSelect from '@/components/select/SourceTypeSelect'

export default {
  name: 'UpdateForm',
  components: {
    AlertStrategyConditionSelect,
    SourceSelect,
    SourceTypeSelect
  },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        source_type: 'host',
        condition: 'in_list',
        sources: []
      },
      rules: {
        source_type: [{ message: '请选择告警事件源', required: true, trigger: 'change' }],
        condition: [{ message: '请选择触发条件', required: true, trigger: 'change' }],
        sources: [{ message: '请选择资源', required: true, trigger: 'change' }]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getAlertStrategyTrigger(id).then(res => {
        const data = res.data
        this.form.source_type = data.source_type
        this.form.condition = data.condition
        data.sources.forEach(source => {
          this.form.sources.push({ key: source.id, label: source.name })
        })
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = {
        source_type: 'host',
        condition: 'in_list',
        sources: []
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            source_type: this.form.source_type,
            condition: this.form.condition,
            source_ids: this.form.sources.map(source => source.key)
          }
          this.loading = true
          updateAlertStrategyTrigger(this.id, data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

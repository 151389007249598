import request from '@/utils/request'

const urlPrefix = '/alert-strategy-triggers'

export function getAlertStrategyTrigger (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlertStrategyTrigger (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteAlertStrategyTrigger (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getAlertStrategyTriggerList (params) {
  return request.get(urlPrefix, { params })
}

<template>
  <a-modal :confirm-loading="loading" title="编辑通知规则" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="方式" prop="method">
        <alert-notification-method-select v-model="form.method" :disabled="true"></alert-notification-method-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.method && form.method !== 'email'"
        label="Webhook"
        prop="webhook"
        :rules="[
          { message: '请输入 Webhook', required: true, trigger: 'blur' },
          { message: '请输入正确的 URL', type: 'url', trigger: 'blur' }
        ]"
      >
        <a-input v-model="form.webhook" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="用户" prop="users">
        <user-select v-model="form.users" :label-in-value="true" mode="multiple"></user-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getAlertStrategyNotification,
  updateAlertStrategyNotification
} from '@/api/alert-strategy-notification'
import AlertNotificationMethodSelect from '@/components/select/AlertNotificationMethodSelect'
import UserSelect from '@/components/select/UserSelect'

export default {
  name: 'UpdateForm',
  components: {
    AlertNotificationMethodSelect,
    UserSelect
  },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        method: undefined,
        webhook: undefined,
        users: []
      },
      rules: {
        method: [{ message: '请选择通知方式', required: true, trigger: 'blur' }],
        users: [{ message: '请选择通知用户', required: true, trigger: 'change' }]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getAlertStrategyNotification(id).then(res => {
        const data = res.data
        this.form.method = data.method
        this.form.webhook = data.webhook
        data.users.forEach(user => {
          this.form.users.push({ key: user.id, label: user.nickname })
        })
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = {
        method: undefined,
        webhook: undefined,
        users: []
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            method: this.form.method,
            webhook: this.form.webhook,
            user_ids: this.form.users.map(user => user.key)
          }
          this.loading = true
          updateAlertStrategyNotification(this.id, data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelection"
      :scroll="{ x: scrollX }"
      style="margin-bottom: 16px;"
    >
      <template slot="sourceType" slot-scope="record">
        <a-tag color="blue">{{ $t(`source_type.${record}`) }}</a-tag>
      </template>
      <template slot="condition" slot-scope="record">{{ $t(`trigger_condition.${record}`) }}</template>
      <template slot="sources" slot-scope="record">
        <a-tag v-for="source in record" :key="source.id">{{ source.name }}</a-tag>
      </template>
      <template slot="operation" slot-scope="record">
        <template v-if="permission.update">
          <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <a-divider type="vertical"></a-divider>
          <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
            <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
            <a style="color: #f5222d;">
              <a-space style="user-select: none;">
                <a-icon type="delete"></a-icon>
                <span>删除</span>
              </a-space>
            </a>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
    <update-form v-if="permission.update" ref="updateForm" @ok="fetch"></update-form>
  </div>
</template>

<script>
import {
  deleteAlertStrategyTrigger,
  getAlertStrategyTriggerList
} from '@/api/alert-strategy-trigger'
import { hasPermission } from '@/utils'
import UpdateForm from './modules/UpdateForm'

export default {
  name: 'TriggerTab',
  components: { UpdateForm },
  props: {
    alertStrategyId: { type: String, required: true }
  },
  data () {
    return {
      form: { page: 1, page_size: 10 },
      columns: [
        { dataIndex: 'source_type', title: '事件源', width: 200, scopedSlots: { customRender: 'sourceType' } },
        { dataIndex: 'condition', title: '触发条件', width: 200, scopedSlots: { customRender: 'condition' } },
        { dataIndex: 'sources', title: '资源', width: 200, scopedSlots: { customRender: 'sources' } },
        { title: '操作', fixed: 'right', width: 160, scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    },
    permission () {
      return {
        update: hasPermission('strategy.update')
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getAlertStrategyTriggerList({ alert_strategy_id: this.alertStrategyId }).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    confirm (id) {
      deleteAlertStrategyTrigger(id).then(res => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>

import request from '@/utils/request'

const urlPrefix = '/alert-strategy-notifications'

export function getAlertStrategyNotification (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlertStrategyNotification (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteAlertStrategyNotification (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getAlertStrategyNotificationList (params) {
  return request.get(urlPrefix, { params })
}

<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="告警策略" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <boolean-status-badge false-name="停用" true-name="启用" :value="detail.active"></boolean-status-badge>
        </template>
        <template slot="extra">
          <a @click="$refs.updateForm.show(detail.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="严重性">
            <severity-tag v-for="severity in detail.severities" :key="severity" :severity="severity"></severity-tag>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :bordered="false" title="触发规则" style="margin-bottom: 16px;">
      <trigger-tab :alert-strategy-id="$route.params.id"></trigger-tab>
    </a-card>

    <a-card :bordered="false" title="通知规则">
      <notification-tab :alert-strategy-id="$route.params.id"></notification-tab>
    </a-card>
  </div>
</template>

<script>
import { getAlertStrategy } from '@/api/alert-strategy'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import SeverityTag from '@/components/tag/SeverityTag'
import UpdateForm from '../modules/UpdateForm'
import NotificationTab from './modules/NotificationTab'
import TriggerTab from './modules/TriggerTab'

export default {
  name: 'AlertStrategyDetail',
  components: {
    BooleanStatusBadge,
    NotificationTab,
    SeverityTag,
    TriggerTab,
    UpdateForm
  },
  data () {
    return {
      detail: {
        project: {},
        severities: []
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getAlertStrategy(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
